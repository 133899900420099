import { environment } from '@env/environment';

export const holidayApiRoutes = {
  holidayBalance: `${environment.employeeHubApiRoot}holiday-balance`,
  holidayEntitlement: `${environment.employeeHubApiRoot}holiday-entitlement`,
  myHolidayRequests: `${environment.employeeHubApiRoot}my-holiday-requests`,
  holidayApprovals: `${environment.employeeHubApiRoot}holiday-approvals`,
  myTeamCalendar: `${environment.employeeHubApiRoot}my-holidays/group-calendar`,
  myApprovedHoliday: `${environment.employeeHubApiRoot}my-approved-holiday`
};
