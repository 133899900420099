import { ChangeDetectionStrategy, Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { BehaviorSubject, debounceTime, distinctUntilChanged, filter, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AbstractDataset } from '@entity-framework/datasets/abstract-dataset';
import { LookupDataProvider } from '@entity-framework/entity-search/lookup.data-provider';
import { AbstractFormFieldComponent } from '../abstract-form-field.component';
import { map, switchMap } from 'rxjs/operators';
import { SelectTheme } from '@design/forms/dataset-select/dataset-select.component';

@UntilDestroy()
@Component({
  selector: 'app-dataset-search-ahead',
  templateUrl: './dataset-search-ahead.component.html',
  styleUrls: ['./dataset-search-ahead.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DatasetSearchAheadComponent<TItem, TValue> extends AbstractFormFieldComponent implements OnInit {
  private liveLookup = false;
  private _lookupDataProvider: LookupDataProvider<AbstractDataset<TItem, TValue>>;
  filteredItems$ = new BehaviorSubject<TItem[]>([]);
  noResults$ = new Subject<boolean>();
  searchInput: UntypedFormControl = new UntypedFormControl();

  @Input() dataset: AbstractDataset<TItem, TValue>;

  @Input() formLabel?: string;
  @Input() searchPlaceholder?: string;
  @Input() propertyToMatch: string;
  @Input() noResultsText: string;
  @Input() focus = false;
  @Input() selectTheme: SelectTheme = 'standard';

  @Input() set liveLookupDataProvider(lookupDataProvider: LookupDataProvider<AbstractDataset<TItem, TValue>>) {
    if (this.liveLookup) return;
    this._lookupDataProvider = lookupDataProvider;
    this.liveLookup = true;

    this.searchInput.valueChanges
      .pipe(
        untilDestroyed(this),
        filter((f) => (f ?? '').trim().length),
        debounceTime(300),
        distinctUntilChanged(),
        switchMap((searchTerm) => {
          this.noResults$.next(false);
          return lookupDataProvider.lookup$(searchTerm).pipe(map((data) => data));
        })
      )
      .subscribe(this.applyDataset);
  }

  @Input() defaultLiveLookupValue?: string;

  private applyDataset = (dataset: AbstractDataset<TItem, TValue>) => {
    this.dataset = dataset;
    this.noResults$.next(!dataset.items.length);
    this.filteredItems$.next(dataset.items);
  };

  private _matSelect: MatSelect;
  @ViewChild(MatSelect)
  set matSelect(matSelect: MatSelect) {
    this._matSelect = matSelect;
    if (this.focus === false || this.defaultLiveLookupValue) return;
    setTimeout(() => {
      matSelect.open();
    }, 500);
  }

  ngOnInit() {
    super.ngOnInit();
    if (this.liveLookup) {
      if (this.defaultLiveLookupValue) {
        this._lookupDataProvider.lookup$(this.defaultLiveLookupValue).subscribe((dataset) => {
          this.applyDataset(dataset);
          this.formControl.setValue(dataset.getValue(dataset.items[0]));
        });
      }
      return;
    }

    this.initLocalLookup();
  }

  onContainerClick() {
    this._matSelect.focus();
    this._matSelect.open();
  }

  private initLocalLookup() {
    this.filteredItems$.next(this.dataset.items);
    this.searchInput.valueChanges.pipe(untilDestroyed(this)).subscribe((searchTerm) => {
      this.filterOptions(searchTerm);
    });
  }

  private filterOptions(searchTerm) {
    if (!this.dataset.items) return;

    if (!searchTerm) {
      this.filteredItems$.next(this.dataset.items.slice());
      return;
    }

    const filtered = this.dataset.items.filter((item) => item[this.propertyToMatch].toLowerCase().indexOf(searchTerm.toLowerCase()) > -1);
    this.filteredItems$.next(filtered);
  }
}
