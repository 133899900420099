import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { CalendarDayEvents, CalendarEvent, calendarIconPath, EventStatus } from '@design/components/events-calendar/state/calendar';
import { CalendarEventService } from '@design/components/events-calendar/calendar-event.service';

@Component({
  selector: 'app-calendar-day-icon',
  templateUrl: './calendar-day-icon.component.html',
  styleUrl: './calendar-day-icon.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CalendarDayIconComponent {
  protected readonly EventStatus = EventStatus;

  multipleEventIconPath = `${calendarIconPath}/mixed-day.svg`;

  @Input() day: CalendarDayEvents;
  @Input() useSmallIcon = false;

  @HostBinding('class.is-person-row') @Input() isPersonRow = false;

  constructor(private calendarEventService: CalendarEventService) {}

  getEventCss = (calendarEvent: CalendarEvent): Record<string, boolean> =>
    this.calendarEventService.getEventCss(calendarEvent, this.useSmallIcon);

  getMixedEventCss = (): Record<string, boolean> => this.calendarEventService.getMixedEventCss(this.useSmallIcon);

  private getImageType = (calendarEvent: CalendarEvent) => {
    switch (calendarEvent.eventStatus) {
      case EventStatus.Approved:
        return 'booked';
      case EventStatus.Requested:
        return 'requested';
      case EventStatus.Cancellation:
        return 'cancellation';
      case EventStatus.Sickness:
        return 'sickness';
      case EventStatus.ApprovedAbsence:
        return 'approved-absence';
      case EventStatus.AbsenceRequested:
        return 'absence-requested';
      case EventStatus.ApprovedAbsenceCancellation:
        return 'absence-cancellation';
    }
  };
  getImgSrc = (calendarEvent: CalendarEvent, duration: string) => `${calendarIconPath}/${this.getImageType(calendarEvent)}-${duration}.svg`;
}
