import * as moment from 'moment';
import { EntityDataset } from '@entity-framework/datasets/entity-dataset';
import { padNumber } from '@shared-utils';
import { AbstractEntity } from '@entity-framework/entity-record/state/abstract-entity';
import { AbsenceCategory } from '@app/employee-hub/employee-leave/employee-leave';
import { AbstractDataset } from '@entity-framework/datasets/abstract-dataset';
import { toTitleCase } from '@utils/string-utils';

// https://devhints.io/moment
export function formatDate(
  date: string | undefined | null | Date,
  format: string = 'DD/MM/YYYY',
  valueWhenNullUndefinedOrEmpty: string = '-'
) {
  if (!(date instanceof Date) && !(date && date.length)) return valueWhenNullUndefinedOrEmpty;
  return moment(date).format(format);
}

export function formatTime(time: string | undefined | null, format: 'LT' | 'LTS' = 'LT', storedFormat: string = 'hh:mm a') {
  if (!(time && time.length)) {
    return '';
  }

  const parsedDate = new Date(time);

  if (parsedDate instanceof Date) {
    return moment(parsedDate).format(storedFormat);
  } else {
    console.error('error parsing time string to date. string: ' + time);
    return '';
  }
}

export function getShortTextDateTime(date: Date, valueWhenNullUndefinedOrEmpty: string = '-', locale: string = 'default'): string {
  const thisDate = new Date(date);
  if (!date || isNaN(thisDate.getDate())) return valueWhenNullUndefinedOrEmpty;

  return thisDate.toLocaleString(locale, {
    hour12: false,
    year: 'numeric',
    month: 'short',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit'
  });
}

export function getDurationInHours(startTime: string, endTime: string, storedFormat: string = 'HH:mm:ss'): number {
  const startTimeFormatted = moment(startTime, storedFormat);
  const endTimeFormatted = moment(endTime, storedFormat);
  return moment.duration(endTimeFormatted.diff(startTimeFormatted)).asHours();
}

export function utcDate(date?: string, format = 'DD/MM/YYYY') {
  const localDate = date ? moment.utc(date, format) : moment.utc();
  return localDate.toISOString();
}

export function extractDateFromString(date: string) {
  if (!(date && date.length)) return null;
  return moment(date).toDate();
}

export function toDayMonth(date: string) {
  return moment(date).format('MMM Do');
}

export function isDateValid(date: moment.Moment, minDate: moment.Moment = moment('1900-01-01')): boolean {
  return date.isValid() && date.isSameOrAfter(minDate);
}

export function isToday(date: Date) {
  const today = new Date();
  const toCompare = new Date(date);
  today.setHours(0, 0, 0, 0);
  toCompare.setHours(0, 0, 0, 0);
  return toCompare.getTime() === today.getTime();
}

export function isOverdue(date: Date) {
  const today = new Date();
  const toCompare = new Date(date);
  today.setHours(0, 0, 0, 0);
  toCompare.setHours(0, 0, 0, 0);
  return toCompare.getTime() < today.getTime();
}

export const hoursDataSet = new EntityDataset([...Array(24).keys()].map((i) => ({ id: i + 1, name: padNumber(i + 1, 2) })));

export const minuteSpansDataSet = new EntityDataset([0, 15, 30, 45].map((i) => ({ id: i, name: padNumber(i, 2) })));
