import { Routes } from '@angular/router';
import { AddEmployeeCarryForwardDaysModule } from '@app/employees/employee-leave/carry-forward-days/add/add-employee-carry-forward-days.module';
import { EditHolidaySchemeModule } from '@app/employees/employee-leave/holiday-scheme/edit/edit-holiday-scheme.module';
import { AdminCreateHolidayModalModule } from '@app/employees/employee-leave/approved-holiday-record/admin-create-holiday-modal.module';

// modal routes only work at the root level...

export const employeeLeaveModalRoutes: Routes = [
  {
    path: 'employee-leave/:employeeId/:entitlementId',
    outlet: 'secondaryModal',
    loadChildren: () => import('./employee-leave-modal.module').then((m) => m.EmployeeLeaveModalModule)
  },
  {
    path: 'holiday-allowance/:employeeId/:allocationId',
    outlet: 'tertiaryModal',
    loadChildren: () => import('./holiday-allowance/edit/edit-holiday-allowance.module').then((m) => m.EditHolidayAllowanceModule)
  },
  {
    path: 'exclusion-set/:employeeId/:id',
    outlet: 'tertiaryModal',
    loadChildren: () => import('./exclusion-sets/edit/edit-employee-exclusion-set.module').then((m) => m.EditEmployeeExclusionSetModule)
  },
  {
    path: 'exclusion-set/:employeeId',
    outlet: 'tertiaryModal',
    loadChildren: () => import('./exclusion-sets/edit/edit-employee-exclusion-set.module').then((m) => m.EditEmployeeExclusionSetModule)
  },
  {
    path: 'carry-forward-days',
    outlet: 'modal',
    loadChildren: () =>
      import('./carry-forward-days/add/add-employee-carry-forward-days.module').then((m) => m.AddEmployeeCarryForwardDaysModule)
  },
  {
    path: 'edit-sickness/:sicknessId',
    outlet: 'modal',
    loadChildren: () => import('./sickness/employee-sickness.module').then((m) => m.EmployeeSicknessModule)
  },
  {
    path: 'edit-approved-absence/:absenceId',
    outlet: 'modal',
    loadChildren: () =>
      import('@app/employees/employee-leave/approved-absence-admin/admin-absence.module').then((m) => m.AdminAbsenceModule)
  },
  {
    path: 'admin/holiday/create',
    outlet: 'modal',
    loadChildren: () =>
      import('@app/employees/employee-leave/approved-holiday-record/admin-create-holiday-modal.module').then(
        (m) => m.AdminCreateHolidayModalModule
      )
  },
  {
    path: 'scheme-settings',
    outlet: 'modal',
    loadChildren: () => import('./holiday-scheme/holiday-scheme.module').then((m) => m.HolidaySchemeModule)
  },
  {
    path: 'edit-scheme-settings/:schemeId',
    outlet: 'secondaryModal',
    loadChildren: () => import('./holiday-scheme/edit/edit-holiday-scheme.module').then((m) => m.EditHolidaySchemeModule)
  }
];
