import { Directive, EventEmitter, Input, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AbstractDataset } from '@entity-framework/datasets/abstract-dataset';
import { AbstractControlValueAccessor } from './abstract-control-value-accessor';

@Directive()
export abstract class AbstractDatasetComponent<TItem, TValue> extends AbstractControlValueAccessor<TValue[]> {
  @Input() label = '';
  @Input() multiple = true;
  @Input() dataset!: AbstractDataset<TItem, TValue>;
  @Input() helpTextFunction?: (TItem) => string;
  @Input() itemEnabledFunction?: (item: TItem, selectedValues: TValue[]) => boolean;

  @Input() isSetEnabled = true;
  @Output() isSelectedEvent = new EventEmitter<TValue[]>();

  protected selectedItems: TValue[] = [];
  protected unselectedItems: TItem[] = [];
  protected subject = new BehaviorSubject<TValue[]>([]);

  selectedItems$ = this.subject.asObservable();

  isSelected = (item: TItem) => {
    const value = this.dataset.getValue(item);
    return this.selectedItems.includes(value);
  };

  toggleItem = (item: TItem) => {
    const value = this.dataset.getValue(item);
    const isEnabled = this.isItemEnabled(item);
    const isSelected = this.selectedItems.includes(value);

    // allow de-selecting even if disabled, to avoid existing users being stuck with invalid permissions
    if (isSelected) {
      this.selectedItems = this.selectedItems.filter((i) => i !== value);
      this.unselectedItems.push(item);
    } else if (isEnabled) {
      if (this.multiple) {
        this.selectedItems.push(value);
      } else {
        let itemsToUnselect = this.dataset.getFormValue(this.dataset.getInitialItems(this.selectedItems)) as TItem[];
        this.unselectedItems.push(...itemsToUnselect);
        this.selectedItems = [value];
      }

      this.unselectedItems = this.unselectedItems.filter((x) => x !== item);
    }

    this.isSelectedEvent.emit(this.selectedItems);

    if (isEnabled || isSelected) {
      this.updateValue();
    }
  };

  selectItem = (item: TItem) => {
    const value = this.dataset.getValue(item);
    this.selectedItems = [value];

    this.updateValue();
  };

  private updateValue() {
    this.subject.next(this.selectedItems);

    this.logger.trace('AbstractDatasetComponent: updated selected items: ', this.selectedItems);

    const formValue = this.dataset.getFormValue(this.selectedItems as any[]);

    this.logger.trace('AbstractDatasetComponent: updated form value: ', formValue);

    super.writeValue(formValue);

    this.onChange(this.value);
  }

  writeValue(value: TValue[]): void {
    this.logger.trace('AbstractDatasetComponent: initializing form value: ', value);

    value = value ?? [];
    super.writeValue(value);

    this.logger.trace('AbstractDatasetComponent: dataset: ', this.dataset);

    this.selectedItems = this.dataset.getInitialItems(this.value) || [];

    this.unselectedItems = this.dataset.items.filter((item) => !this.selectedItems.includes(this.dataset.getValue(item)));

    this.isSelectedEvent.emit(this.selectedItems);

    this.subject.next(this.selectedItems);
  }

  isItemEnabled(item: TItem): boolean {
    return this.isSetEnabled && (!this.itemEnabledFunction || this.itemEnabledFunction(item, this.selectedItems));
  }

  contains = (source: TItem[], item: TItem) => this.dataset.contains(source, item);
}
