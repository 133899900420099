import { Directive, EventEmitter, inject, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DateTime } from 'luxon';
import { CalendarDayEvents, CalendarViewMode, EventModel, EventStatus, isSunday, isWeekend, weekdays } from './state/calendar';
import { MonthCalendarComponentStateService } from './state/month-calendar-component-state.service';
import { CalendarNavigatorStateService } from './state/calendar-navigator.state-service';
import { CalendarEventService } from '@design/components/events-calendar/calendar-event.service';
import { ActionMenuItemConfig } from '@design/buttons/action-config';

@UntilDestroy()
@Directive()
export abstract class AbstractCalendarEventsComponent {
  navigatorStateService = inject(CalendarNavigatorStateService);
  componentStateService = inject(MonthCalendarComponentStateService);

  weekdays = weekdays;

  private router = inject(Router);
  private calendarEventService = inject(CalendarEventService);
  private eventSubscription: Subscription;

  @Input() set eventModels(eventModels: EventModel[]) {
    this.componentStateService.eventModels = eventModels;
  }

  @Input() set calendarDate(activeDate: DateTime) {
    this.componentStateService.calendarDate = activeDate;
  }

  @Input() calendarViewMode: CalendarViewMode = 'month';
  @Input() canClickAvailableDays = true;
  @Input() actionMenuItems: ActionMenuItemConfig[];

  @Output() dayClick$ = new EventEmitter<CalendarDayEvents>();

  get activeMonth(): string {
    return this.componentStateService.calendarDate?.toFormat('MMM');
  }

  clickDay = (day: CalendarDayEvents | null) => {
    if (!day || day.fullDayEvent?.eventStatus === EventStatus.NonWorkingDay) return;
    if (!day.hasEvent && this.actionMenuItems?.length) return;

    if (day.hasMultipleDayEvents || day.hasMultipleMixedEvents) {
      if (this.eventSubscription) this.eventSubscription.unsubscribe();
      this.eventSubscription = this.calendarEventService.navigateEvents$
        .pipe(untilDestroyed(this))
        .subscribe((calendarDay) => this.clickDay(calendarDay));

      this.openMultiEventSelector(day);
    } else if (day.isHalfDay) this.dayClick$.emit(this.calendarEventService.transformEventForClick(day.amEvent ?? day.pmEvent, day));
    else this.dayClick$.emit(day);
  };

  private openMultiEventSelector = (calendarDay: CalendarDayEvents) => {
    this.router
      .navigate(['', { outlets: { selector: ['calendar', 'multi-event-selector'] } }], {
        queryParamsHandling: 'preserve',
        skipLocationChange: true,
        state: calendarDay // bound to route data in routing config + modal activation component
      })
      .then();
  };

  getDayCss(day: CalendarDayEvents): Record<string, boolean> {
    if (!day) return {};

    const classes = {
      today: day.isToday,
      'has-event': day.hasEvent,
      'has-multiple-events': day.hasMultipleDayEvents
    };

    if (!day.hasEvent) {
      classes['cursor-pointer'] = classes['available'] = this.canClickAvailableDays;
      classes['no-event'] = !day.isNonWorkingDay;
      classes['non-working-day'] = day.isNonWorkingDay;
    }

    classes['is-weekend'] = isWeekend(day.date.weekday);
    classes['is-sunday'] = isSunday(day.date.weekday);

    return classes;
  }
}
