export const bureauRoutes = {
  dashboard: 'bureau/dashboard',
  organisations: '/bureau/organisations',
  teams: '/bureau/teams',
  users: '/bureau/users',
  reports: 'bureau/reports',
  bankFiles: 'bureau/bank-files?bankFileStatus=New'
};

export const bureauAdminRoutes = {
  admin: 'bureau/admin',
  bankFileValidation: 'bureau/admin/bank-file-validation'
};
