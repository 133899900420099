<div
  class="calendar"
  *ngIf="navigatorStateService.activeDate$ | async as activeDate"
  [attr.data-year]="activeDate.year"
  [attr.data-month]="activeDate.month"
>
  <app-month-navigator *ngIf="displayMonthNavigator"></app-month-navigator>
  <ng-content select="[header]"></ng-content>

  <div class="table-wrap" *ngVar="calendarMonth.length + 1 as colspan">
    <table>
      <colgroup>
        <col />
        <col [ngClass]="getHeaderDayCss(day)" *ngFor="let day of calendarMonth" />
      </colgroup>
      <thead>
        <tr>
          <th><app-body-md bold>Name</app-body-md></th>
          <th [ngClass]="getHeaderDayCss(day)" *ngFor="let day of calendarMonth">
            {{ day.day }}<br />{{ weekdays[day.weekday - 1].substring(0, 1) }}
          </th>
        </tr>
      </thead>

      <tbody>
        @if (this.teamEventData.teamTitle && this.teamEventData.subordinatesTitle) {
          <tr class="divider" tid="team-divider">
            <td [attr.colspan]="colspan">{{ this.teamEventData.teamTitle }}</td>
          </tr>
        }
        @if (this.teamEventData.teamEvents) {
          <tr
            app-person-events-month-row
            [ngClass]="getRowEventCss(personEvents)"
            *ngFor="let personEvents of this.teamEventData.teamEvents"
            [personEvents]="personEvents"
            [rowSelected]="activePersonId === personEvents.iqPersonId || _highlightedPersonId === personEvents.iqPersonId"
            [interactive]="!activePersonId || activePersonId === personEvents.iqPersonId"
            [canClickAvailableDays]="canClickAvailableDays"
            (dayClick$)="dayClick($event, personEvents)"
            [actionMenuItems]="actionMenuItems"
            [calendarDate]="activeDate"
            [attr.data-employee]="personEvents.forename + ' ' + personEvents.surname"
          ></tr>
        }
        @if (this.teamEventData.teamTitle && this.teamEventData.subordinatesTitle) {
          <tr class="divider" tid="subordinates-divider">
            <td [attr.colspan]="colspan">{{ this.teamEventData.subordinatesTitle }}</td>
          </tr>
        }
        @if (this.teamEventData.subordinateEvents) {
          <tr
            app-person-events-month-row
            [ngClass]="getRowEventCss(personEvents)"
            *ngFor="let personEvents of this.teamEventData.subordinateEvents"
            [personEvents]="personEvents"
            [rowSelected]="activePersonId === personEvents.iqPersonId || _highlightedPersonId === personEvents.iqPersonId"
            [interactive]="!activePersonId || activePersonId === personEvents.iqPersonId"
            [canClickAvailableDays]="canClickAvailableDays"
            (dayClick$)="dayClick($event, personEvents)"
            [actionMenuItems]="actionMenuItems"
            [calendarDate]="activeDate"
            [attr.data-employee]="personEvents.forename + ' ' + personEvents.surname"
          ></tr>
        }
      </tbody>
    </table>
  </div>
</div>
