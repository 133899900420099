import { Inject, Injectable, Injector, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import { generate as shortUid } from 'short-uuid';
import { AuthenticationService } from '@auth-n/authentication.service';
import {
  PrivateOrganisationLoginDialogComponent,
  PrivateOrganisationLoginResponse
} from '@auth-n/private-organisation-login/private-organisation-login-dialog.component';
import { ActiveAuthenticationStateService } from '@auth-n/state/active-authentication.state-service';
import { LoggingService } from '@logging/logging.service';
import { OnscreenMessagingService } from '@pattern-library/onscreen-messaging/onscreen-messaging.service';
import { OrganisationPropertiesModel } from '@security/organisations/models/organisation-properties.model';
import { organisationRoutes } from '@security/organisations/organisation-routes';
import { employerRoutes } from '@app/employers/employer-routes';
import { payrollRoutes } from '@app/payrolls/payrolls-routes';
import { MyEmploymentModel, Member } from '../users/models/member';
import {
  ApplicationPermission,
  BureauProfilePermission,
  FeaturePack,
  FeaturePackPermission,
  GroupPermission,
  GroupWithPermission,
  OrganisationPermission,
  UserRole
} from '../users/models/user';
import { userRoutes } from '../users/user-routes';
import { ActiveUser } from './active-user';
import { OrganisationPropertiesDataProvider } from './organisation-properties.data-provider';
import { createStore, select, setProps, withProps } from '@ngneat/elf';
import { ActiveSecurityContext } from './active-security-context';
import { employeeHubRoutes } from '@app/employee-hub/employee-hub-routes';
import { ServiceType } from '../organisations/organisation-hub/organisation-edit/organisation-details/state/organisation.state';
import { HotjarService, SegmentAnalyticsService } from '@analytics';
import { FullScreenSpinnerService } from '@design/spinners/fullscreen-spinner/fullscreen-spinner.service';
import { ActiveMembershipSelectorDialogComponent } from '@security/active-security/active-membership-selector/active-membership-selector-dialog.component';
import { integrationsRoutes } from '@app/integrations-admin/integrations-routes';
import { DateTime } from 'luxon';
import { HttpHeaders } from '@angular/common/http';
import { ActiveMemberDataProvider } from '@security/active-security/active-member.data-provider';
import { getAdminHubLandingRoute } from '@app/admin-hub/admin-hub-routing.module';
import { BureauProfile } from '@app/bureau/bureau-profile';
import { bureauRoutes } from '@app/bureau/bureau-routes';
import { ActiveCintraProductService } from '@app/active-cintra-product.service';
import { UserAttributesModel } from '@security/active-security/models/user-attributes.model';
import { groupRoutes } from '@app/access-control/groups/group-routes';
import { messagingRoutes } from '@app/messaging/messaging-routes';
import { AppHostService } from '../../../framework/analytics/app-host.service';

const orgImpKey: string = 'x-impersonate-organisation-id';
const initialState = {} as ActiveSecurityContext;

/**
 * Provides models management for the active user including their active membership (organisation) /w role(s).
 */
@Injectable({ providedIn: 'root' })
export class ActiveSecurityContextStateService implements OnDestroy {
  /**
   * Router not available from an APP_INITIALIZER (see AuthenticationSetupService > AuthenticationService > SecurityStateService >
   * here), so will locate it directly using the Injector ...
   */
  private router: Router | null = null;

  private readonly store = createStore({ name: 'active-security' }, withProps(initialState));

  /**
   * Observable of the current authenticated user.
   */
  activeUser$ = this.store.pipe(
    select((s) => s.activeUser),
    filter(Boolean)
  ) as Observable<ActiveUser>;
  /**
   * Observing the fetching active user flag - so that permission-based routes are not resolved until fetched.
   */
  fetchingActiveUser$ = this.store.pipe(select((s) => s.fetchingActiveUser));
  /**
   * Observable of the active user's organisations where admin (e.g. payroll admin, user admin).
   */
  membershipsWithAdminAccess$ = this.store.pipe(select((state) => state.membershipsWithAdminAccess));
  hasMultipleMembershipsWithAdminAccess$ = this.membershipsWithAdminAccess$.pipe(map((m) => !!m && m.length > 1));
  /**
   * Observable of the active user's organisation-in-context.
   */
  activeMembership$ = this.store.pipe(
    select((state) => state),
    filter((s) => !s.fetchingActiveUser && !!s.activeMembership),
    map((s) => s.activeMembership)
  ) as Observable<Member>;

  activeBureau$ = this.store.pipe(
    select((s) => s.activeBureau),
    filter((activeBureau) => activeBureau != null)
  );

  maxLoginDate$ = this.store.pipe(select((s) => s.activeEmployment?.maxLoginDate));

  constructor(
    private injector: Injector,
    private dialog: MatDialog,
    private authenticationService: AuthenticationService,
    private authenticationStateService: ActiveAuthenticationStateService,
    private activeMemberDataProvider: ActiveMemberDataProvider,
    private logger: LoggingService,
    private analyticsService: SegmentAnalyticsService,
    private organisationPropertiesDataProvider: OrganisationPropertiesDataProvider,
    private hotjarService: HotjarService,
    private fullscreenSpinnerService: FullScreenSpinnerService,
    private appHostService: AppHostService
  ) {}

  private flattenApplicationPermissions = (userRoles: UserRole[]): ApplicationPermission[] => {
    let permissions: ApplicationPermission[] = [];
    userRoles
      .filter((ur) => !ur.organisationId)
      .forEach((ur) => {
        const appPerms = ur.role.permissions.filter((p) => p.scope === 'Application').map((p) => p.id) as ApplicationPermission[];
        permissions = permissions.concat(appPerms);
      });
    return permissions;
  };

  private flattenOrganisationPermissions = (memberRoles: UserRole[]): OrganisationPermission[] => {
    let permissions: OrganisationPermission[] = [];
    memberRoles.forEach((ur) => {
      const orgPerms = ur.role.permissions.filter((p) => p.scope === 'Organisation').map((p) => p.id) as OrganisationPermission[];
      permissions = permissions.concat(orgPerms);
    });
    return permissions;
  };

  private flattenGroupPermissions = (userRoles: UserRole[], organisationId: string): GroupWithPermission[] => {
    let allPermissions: GroupWithPermission[] = [];

    userRoles
      .filter((ur) => ur.organisationId === organisationId)
      .forEach((ur) => {
        const groupPerms = ur.role.permissions
          .filter((p) => p.scope === 'GroupUser')
          .map((p) => ({ groupId: ur.groupId, permission: p.id as GroupPermission }));
        allPermissions = allPermissions.concat(groupPerms);
      });

    return allPermissions;
  };

  private flattenFeaturePackPermissions = (featurePacks: FeaturePack[]): FeaturePackPermission[] => {
    let permissions: FeaturePackPermission[] = [];
    featurePacks.forEach((fp) => {
      const perms = fp.permissions.map((p) => p.id) as FeaturePackPermission[];
      permissions = permissions.concat(perms);
    });
    return permissions;
  };

  private flattenBureauProfilePermissions = (userRoles: UserRole[]): BureauProfilePermission[] => {
    let permissions: BureauProfilePermission[] = [];
    userRoles
      .filter((ur) => !ur.organisationId)
      .forEach((ur) => {
        const appPerms = ur.role.permissions.filter((p) => p.scope === 'BureauProfile').map((p) => p.id) as BureauProfilePermission[];
        permissions = permissions.concat(appPerms);
      });
    return permissions;
  };

  private flattenEmployeeAccounts = (members: Member[]): MyEmploymentModel[] => {
    let employeeAccounts: MyEmploymentModel[] = [];
    members.forEach((member) => {
      const employments = member.employments?.filter((e) => !e.accountExpired) ?? [];
      employments.forEach((e) => (e.organisationGuid = member.organisationId));
      employeeAccounts = employeeAccounts.concat(employments);
    });

    return employeeAccounts;
  };

  get activeUser(): ActiveUser {
    return this.store.getValue().activeUser as ActiveUser;
  }

  /**
   * Gets the current user session id (generated for each active browser tab per app load/sign-in or out)
   */
  get sessionId(): string | undefined {
    return this.store.getValue().sessionId;
  }

  get activeBureau(): BureauProfile {
    return this.store.getValue().activeBureau as BureauProfile;
  }

  /**
   * Set the last used bureau profile id
   */
  static set cachedBureauProfileId(bureauProfileId: number | null) {
    localStorage.setItem('bureauProfileId', (bureauProfileId || '').toString());
  }

  /**
   * Get the last used bureau profile id
   */
  static get cachedBureauProfileId(): number | null {
    const val = localStorage.getItem('bureauProfileId');
    return val && val.length ? parseInt(val) : null;
  }

  private get impersonatedUserId(): string {
    return localStorage.getItem('impersonatedUserId');
  }

  startUserImpersonation(impersonatedUserId: string) {
    localStorage.setItem('impersonatedUserId', impersonatedUserId);
    location.href = '/';
  }

  endUserImpersonation() {
    localStorage.removeItem('impersonatedUserId');
    location.href = '/';
  }

  isImpersonated(): boolean {
    return !!this.impersonatedUserId;
  }

  getOrganisationImpersonationOptions(organisationId: string) {
    let headers = new HttpHeaders();
    headers = headers.append(orgImpKey, organisationId);

    return { headers: headers };
  }

  get isEmployeeHubIosUser(): boolean {
    return this.appHostService.isIOS();
  }

  /**
   * Appended to cintra api requests. See {@link: JwtInterceptor}
   */
  getCintraCloudApiRequestHeaders(httpHeaders: HttpHeaders): { [key: string]: string } {
    const requestId = shortUid();
    this.logger.requestId = requestId;

    const organisationId = httpHeaders.keys().includes(orgImpKey)
      ? httpHeaders.get(orgImpKey)
      : this.activeMembership?.organisationId ?? '';

    const headers = {
      'x-organisation-id': organisationId,
      'x-session-id': this.sessionId,
      'x-request-id': requestId,
      'x-timestamp': new Date().getTime().toString(),
      'x-app-host': this.appHostService.appHost
    };

    if (this.activeMembership?.privateDetails) {
      headers['x-organisation-private-details'] = this.activeMembership?.privateDetails;
    }

    if (this.impersonatedUserId) {
      headers['x-impersonate-user-id'] = this.impersonatedUserId;
    }

    if (this.activeEmployment) {
      headers['x-employee-id'] = this.activeEmployment.id.toString();
    }

    this.logger.trace('NEXT API HEADERS: ', headers);
    return headers;
  }

  /**
   * Gets the current user's list of organisations stored in models.
   */
  get memberships(): Member[] {
    return this.store.getValue().memberships || [];
  }

  /**
   * Gets the current user's list of organisations stored in models where they have access to e.g. payrolls.
   */
  get membershipsWithAdminAccess(): Member[] {
    return this.store.getValue().membershipsWithAdminAccess || [];
  }

  get employments(): MyEmploymentModel[] {
    return this.store.getValue().myEmployments || [];
  }

  get activeEmployment(): MyEmploymentModel {
    return this.store.getValue().activeEmployment;
  }

  /**
   * Gets the current user's active organisation.
   */
  get activeMembership(): Member {
    return this.store.getValue().activeMembership;
  }

  get activeApplicationPermissions(): ApplicationPermission[] {
    return this.store.getValue().activeApplicationPermissions ?? [];
  }

  get activeOrganisationPermissions(): OrganisationPermission[] {
    return this.store.getValue().activeOrganisationPermissions ?? [];
  }

  get activeBureauProfilePermissions(): BureauProfilePermission[] {
    return this.store.getValue().activeBureauProfilePermissions ?? [];
  }

  get activeFeaturePackPermissions(): FeaturePackPermission[] {
    return this.store.getValue().activeFeaturePackPermissions ?? [];
  }

  get activeEmploymentFeaturePackPermissions(): FeaturePackPermission[] {
    return this.store.getValue().activeEmploymentFeaturePackPermissions ?? [];
  }

  get groupPermissions(): GroupWithPermission[] {
    return this.store.getValue().groupPermissions ?? [];
  }

  /**
   * Returns true if the user's application permissions contains this permission.
   */
  hasApplicationAuthorityTo(permission: ApplicationPermission): boolean {
    return permission === 'Any' || this.activeApplicationPermissions?.includes(permission);
  }

  /**
   * Returns true if the user's applications permissions contains one of these permissions.
   */
  hasApplicationAuthorityToOneOf(allowedPermissions: ApplicationPermission[]): boolean {
    return allowedPermissions.some((allowedPermission) => this.hasApplicationAuthorityTo(allowedPermission), this);
  }

  /**
   * Returns true if the user's active application permissions contains all of these permissions.
   */
  hasApplicationAuthorityToAllOf(requiredPermissions: ApplicationPermission[]): boolean {
    return requiredPermissions.every((requiredPermission) => this.hasApplicationAuthorityTo(requiredPermission), this);
  }

  /**
   * Returns true if the user's active organisation contains this permission.
   */
  hasBureauProfileAuthorityTo(permission: BureauProfilePermission): boolean {
    return permission === 'Any' || this.activeBureauProfilePermissions?.includes(permission);
  }

  /**
   * Returns true if the user's active bureau contains one of these permissions.
   */
  hasBureauProfileAuthorityToOneOf(allowedPermissions: BureauProfilePermission[]): boolean {
    return allowedPermissions.some((allowedPermission) => this.hasBureauProfileAuthorityTo(allowedPermission), this);
  }

  /**
   * Returns true if the user's active bureau contains all of these permissions.
   */
  hasBureauProfileAuthorityToAllOf(requiredPermissions: BureauProfilePermission[]): boolean {
    return requiredPermissions.every((requiredPermission) => this.hasBureauProfileAuthorityTo(requiredPermission), this);
  }

  /**
   * Returns true if the user's active organisation contains this permission.
   */
  hasOrganisationAuthorityTo(permission: OrganisationPermission): boolean {
    return permission === 'Any' || this.activeOrganisationPermissions?.includes(permission);
  }

  /**
   * Returns true if the user's active organisation contains one of these permissions.
   */
  hasOrganisationAuthorityToOneOf(allowedPermissions: OrganisationPermission[]): boolean {
    return allowedPermissions.some((allowedPermission) => this.hasOrganisationAuthorityTo(allowedPermission), this);
  }

  /**
   * Returns true if the user's active organisation contains all of these permissions.
   */
  hasOrganisationAuthorityToAllOf(requiredPermissions: OrganisationPermission[]): boolean {
    return requiredPermissions.every((requiredPermission) => this.hasOrganisationAuthorityTo(requiredPermission), this);
  }

  hasBureauProfilePermissions(): boolean {
    return this.activeBureauProfilePermissions && this.activeBureauProfilePermissions.length > 0 && this.activeBureau != null;
  }

  hasNoOrganisationPermissions(): boolean {
    return !this.activeOrganisationPermissions || this.activeOrganisationPermissions.length === 0;
  }

  hasGroupAuthorityTo(payload: { groupId: number; permission: GroupPermission }): boolean {
    const filtered = this.groupPermissions.filter((x) => x.groupId === payload.groupId && x.permission === payload.permission);
    return filtered.length > 0;
  }

  hasAnyGroupAuthorityTo(permission: GroupPermission): boolean {
    const filtered = this.groupPermissions.filter((x) => x.permission === permission);
    return filtered.length > 0;
  }

  /**
   * Returns true if this user's feature-pack permissions contains this permission.
   */
  hasFeaturePackAuthorityTo(permission: FeaturePackPermission): boolean {
    return permission === 'Any' || this.activeFeaturePackPermissions?.includes(permission);
  }

  /**
   * Returns true if the active employment's feature-pack permissions contains this permission.
   */
  hasEmploymentFeaturePackAuthorityTo(permission: FeaturePackPermission): boolean {
    return permission === 'Any' || this.activeEmploymentFeaturePackPermissions?.includes(permission);
  }

  /**
   * Returns true if this user's feature-pack permissions contains all of these permission.
   */
  hasFeaturePackAuthorityToAll(permissions: FeaturePackPermission[]): boolean {
    for (let permission of permissions) {
      if (!this.hasFeaturePackAuthorityTo(permission)) {
        return false;
      }
    }
    return true;
  }

  /**
   * Returns true if the user's active feature-pack contains one of these permissions.
   */
  hasFeaturePackAuthorityToOneOf(allowedPermissions: FeaturePackPermission[]): boolean {
    return allowedPermissions.some((allowedPermission) => this.hasFeaturePackAuthorityTo(allowedPermission), this);
  }

  /**
   * Returns true if the active employment's feature-pack contains one of these permissions.
   */
  hasEmploymentFeaturePackAuthorityToOneOf(allowedPermissions: FeaturePackPermission[]): boolean {
    return allowedPermissions.some((allowedPermission) => this.hasEmploymentFeaturePackAuthorityTo(allowedPermission), this);
  }

  canAccessCintraBureau = () =>
    this.hasBureauProfilePermissions() || this.hasApplicationAuthorityToOneOf(['EditBureaux', 'IsCintraSupport']);

  canAccessCintraPayrolls = () =>
    this.isCintraAdmin() || this.hasOrganisationAuthorityToOneOf(['EditUsers', 'AccessPayrolls', 'AccessGroups', 'IsEmployeeManager']);

  canAccessEmployeeHub = () => this.hasFeaturePackAuthorityTo('AccessEmployeeHub') && !!this.activeMembership.iqPersonId;

  canAccessAdminHub = () => this.hasOrganisationAuthorityToOneOf(['AccessAdminHub', 'AccessAdminHubHolidays', 'EditEmployeeSickness']);

  canAccessHolidays = () => this.canAccessEmployeeHub() && this.hasFeaturePackAuthorityTo('AccessEmployeeHubHolidays');

  canAccessSickness = () => this.hasFeaturePackAuthorityTo('AccessSickness');

  canAccessAbsence = () =>
    this.hasFeaturePackAuthorityTo('AccessAbsence') &&
    (this.activeMembership.employeeLeaveSettings?.find((s) => s.key === 'AbsenceCategories')?.intArrayValue ?? []).length > 0;

  canEmployeeViewSickness = () =>
    this.canAccessSickness() &&
    this.activeMembership.employeeLeaveSettings?.find((s) => s.key === 'EmployeesCanViewSickness')?.booleanValue === true;

  canEmployeeAddAbsence = () =>
    this.canAccessAbsence() &&
    this.activeMembership.employeeLeaveSettings?.find((s) => s.key === 'EmployeesCanRequestAbsence')?.booleanValue === true;

  approversCanAddSickness = () =>
    this.canAccessSickness() &&
    this.activeMembership.employeeLeaveSettings?.find((s) => s.key === 'ApproversCanAddSickness')?.booleanValue === true;

  isCintraEmployee = () => {
    const appPerms = this.activeApplicationPermissions;

    return appPerms?.includes('IsCintraEmployee');
  };

  isCintraAdmin = () => {
    const appPerms = this.activeApplicationPermissions;
    return appPerms?.includes('EditOrganisations');
  };

  isBureauUser = (organisationId?: string | null): boolean => {
    var activeBureauId = ActiveSecurityContextStateService.cachedBureauProfileId;

    if (activeBureauId === null) return false;

    organisationId = organisationId ? organisationId : ActiveSecurityContextStateService.cachedOrganisationId;

    const membership = this.activeUser.memberships.find((m) => m.organisationId == organisationId && m.bureauProfile?.id == activeBureauId);

    if (membership) return true;

    return false;
  };

  isOssUser = (organisationId?: string | null): boolean => {
    return this.isCintraEmployee() || this.isBureauUser(organisationId);
  };

  isMessagingOnlyUser() {
    let messagingPermissions: OrganisationPermission[] = ['AccessMessaging', 'DeleteMessages', 'IsOrganisationAdmin'];
    return (
      messagingPermissions.every((element) => this.activeOrganisationPermissions?.includes(element)) &&
      this.activeOrganisationPermissions?.length == messagingPermissions.length
    );
  }

  isServiceTypeSource(payrollId: number): boolean {
    const serviceType = this.activeMembership.payrollSettings.find((x) => x.payrollId === payrollId)?.serviceType ?? ServiceType.SaaS;
    return serviceType === ServiceType.Source;
  }

  /**
   * Get the last used organisation id
   */
  static get cachedOrganisationId(): string | null {
    return localStorage.getItem('organisationId');
  }

  /**
   * Set the last used employee id
   */
  static set cachedEmployeeId(employeeId: number | null) {
    localStorage.setItem('employeeId', (employeeId || '').toString());
  }

  /**
   * Get the last used employee id
   */
  static get cachedEmployeeId(): number | null {
    const val = localStorage.getItem('employeeId');
    return val && val.length ? parseInt(val) : null;
  }

  /**
   * Set the last used organisation id
   */
  static set cachedOrganisationId(organisationId: string | null) {
    localStorage.setItem('organisationId', organisationId || '');
  }

  static get cachedPrivateDetails(): string {
    return localStorage.getItem('privateDetails');
  }

  static set cachedPrivateDetails(privateDetails: string | null) {
    localStorage.setItem('privateDetails', privateDetails);
  }

  /**
   * Update the active organisation in models to be undefined.
   */
  setMembershipInactive() {
    this.logger.trace('ACTIVE SECURITY: (stop transitioning) setting inactive organisation: ');

    this.store.update(
      setProps({
        activeMembership: undefined,
        fetchingActiveUser: false,
        activeApplicationPermissions: undefined,
        activeOrganisationPermissions: undefined,
        activeFeaturePackPermissions: undefined
      })
    );
    document.title = 'Cintra Cloud';
  }

  setActiveUser(activeUser: ActiveUser) {
    const activeApplicationPermissions = this.flattenApplicationPermissions(
      (activeUser?.userRoles ?? []).filter((r) => r.role.scope === 'Application')
    );

    const activeBureauProfilePermissions = this.flattenBureauProfilePermissions(
      (activeUser?.userRoles ?? []).filter((r) => r.role.scope === 'BureauProfile')
    );

    this.store.update(
      setProps({
        activeUser,
        activeApplicationPermissions,
        activeBureauProfilePermissions
      })
    );

    const membershipsWithAdminAccess =
      this.hasApplicationAuthorityTo('AccessAllOrganisations') || this.hasBureauProfileAuthorityTo('IsBureauUser')
        ? activeUser.memberships
        : activeUser.memberships.filter((m) => m.hasOrganisationAdminAccess);

    this.store.update(
      setProps({
        memberships: activeUser.memberships,
        membershipsWithAdminAccess
      })
    );

    this.setActiveBureau();

    this.tryResolveActiveEmployment();
  }

  setActiveBureau(bureauProfileId?: number) {
    let activeBureau: BureauProfile;

    if (bureauProfileId) {
      activeBureau = this.activeUser?.bureauProfiles?.find((b) => b.id === bureauProfileId);
    }

    if (activeBureau == null) {
      activeBureau =
        this.activeUser?.bureauProfiles?.find((b) => b.id === ActiveSecurityContextStateService.cachedBureauProfileId) ||
        this.activeUser?.bureauProfiles?.[0] ||
        null;
    }

    if (activeBureau) {
      ActiveSecurityContextStateService.cachedBureauProfileId = activeBureau.id;

      this.store.update(
        setProps({
          activeBureau
        })
      );
    } else {
      ActiveSecurityContextStateService.cachedBureauProfileId = null;
    }
  }

  private tryResolveActiveEmployment() {
    const activeUser = this.activeUser;

    const myEmployments = this.flattenEmployeeAccounts(activeUser.memberships);
    this.store.update(
      setProps({
        myEmployments
      })
    );

    if (this.isEmployeeHubIosUser) {
      // This is bad, but the IOS work is a time-sensitive hack
      // We need to alter the height calculations because the logo and product navigation get hidden for
      // IOS Employee Hub app users
      document.documentElement.style.setProperty('--logo-row-height', '0rem');
      document.documentElement.style.setProperty('--top-nav-tabs-height', '0rem');

      // Only allow access to the employee hub, so remove any memberships that we dont have an employment for
      let membershipsWithAdminAccess = this.membershipsWithAdminAccess.filter((m) =>
        myEmployments.find((e) => e.organisationGuid === m.organisationId)
      );
      let memberships = this.memberships.filter((m) => myEmployments.find((e) => e.organisationGuid === m.organisationId));
      this.store.update(
        setProps({
          memberships,
          membershipsWithAdminAccess
        })
      );
    }

    this.logger.trace('ACTIVE SECURITY: Setting active employment', myEmployments);

    const accessToEmployeeHubOnly = !this.membershipsWithAdminAccess.length;

    let activeEmployment = myEmployments.length == 1 ? myEmployments[0] : null;
    if (!activeEmployment && myEmployments.length) {
      const lastSeenEmployeeId = ActiveSecurityContextStateService.cachedEmployeeId;
      const lastSeenOrganisationId = ActiveSecurityContextStateService.cachedOrganisationId;
      activeEmployment = lastSeenEmployeeId
        ? myEmployments.find((e) => e.id === lastSeenEmployeeId && e.organisationGuid === lastSeenOrganisationId)
        : null;

      if (!activeEmployment) {
        const sorted = myEmployments.sort((a, b) => {
          const d1 = DateTime.fromISO(a.fromDate);
          const d2 = DateTime.fromISO(b.fromDate);
          return d2.toMillis() - d1.toMillis();
        });

        activeEmployment = sorted.find((e) => e.organisationGuid === lastSeenOrganisationId);

        // the "feature" here is that we don't want employee hub users with multiple employments
        // having to select an organisation when landing on employee hub, but that isn't the case for Cintra Payroll users who still need to

        if (!activeEmployment) activeEmployment = sorted[0];
      }
    }
    ActiveSecurityContextStateService.cachedEmployeeId = activeEmployment?.id;
    if (accessToEmployeeHubOnly) ActiveSecurityContextStateService.cachedOrganisationId = activeEmployment?.organisationGuid;

    if (!activeEmployment) {
      this.logger.trace('ACTIVE SECURITY: No active employment found');

      return;
    }

    const activeEmploymentFeaturePackPermissions = this.flattenFeaturePackPermissions(activeEmployment.featurePacks);

    this.logger.trace(
      'ACTIVE SECURITY: Active employee',
      activeEmployment.employmentId,
      activeEmployment.toDate,
      activeEmployment.maxLoginDate
    );

    this.logger.trace(
      'ACTIVE SECURITY: Employment feature permissions',
      activeEmployment.featurePacks,
      activeEmploymentFeaturePackPermissions
    );

    this.logger.employeeId = activeEmployment.id.toString();

    this.store.update(
      setProps({
        activeEmployment,
        activeEmploymentFeaturePackPermissions
      })
    );
  }

  onActiveEmploymentChanged(activeEmployment: MyEmploymentModel): void {
    this.logger.trace('ACTIVE SECURITY: active employment changed to: ', activeEmployment);

    ActiveSecurityContextStateService.cachedOrganisationId = activeEmployment.organisationGuid;

    ActiveSecurityContextStateService.cachedEmployeeId = activeEmployment.id;

    if (document.location.href == employeeHubRoutes.dashboard) document.location.reload();
    else document.location.href = employeeHubRoutes.dashboard;
  }

  setNewSessionId(sessionId: string) {
    this.store.update(setProps({ sessionId, fetchingActiveUser: true }));
  }

  /**
   * Resolve the Organisation-in-context and fetch the membership record.
   * If the current user has admin access to multiple organisations, ask them to choose.
   * Cache the selection and use it on subsequent visits (unless logging out).
   * (The user is also able to switch context from the header menu.)
   */
  resolveActiveMembership(members: Member[]) {
    const membershipsWithAdminAccess = this.membershipsWithAdminAccess;

    const tryUseCached = () => {
      // check local storage for a previous selection, and that it is still valid
      const cachedOrganisationId = ActiveSecurityContextStateService.cachedOrganisationId || '';
      if (cachedOrganisationId.length) {
        const membership = this.memberships.find((o) => o.organisationId.toUpperCase() === cachedOrganisationId.toUpperCase());
        if (membership) {
          this.onActiveMembershipChanged(membership);
          return true;
        }
      }

      return false;
    };

    // employee hub user pre-selected by tryResolveActiveEmployment above
    if (
      (ActiveSecurityContextStateService.cachedOrganisationId || '').length &&
      !!ActiveSecurityContextStateService.cachedEmployeeId &&
      tryUseCached()
    )
      return;

    // employee hub-only user
    if (!membershipsWithAdminAccess.length && this.memberships.length && tryUseCached()) return;

    // cintra payrolls user
    if (membershipsWithAdminAccess.length === 0) {
      this.fullscreenSpinnerService.reset();
      this.injector.get<Router>(Router).navigateByUrl('no-permissions').then();
      return;
    }

    localStorage.removeItem('privateDetails');

    if (this.hasBureauProfilePermissions()) {
      if (tryUseCached()) {
        return;
      }

      this.onActiveMembershipChanged(membershipsWithAdminAccess[0]);
      return;
    }

    if (membershipsWithAdminAccess.length === 1) {
      this.onActiveMembershipChanged(membershipsWithAdminAccess[0]);
    } else {
      /**
       * Confirm the organisation
       */

      if (tryUseCached()) return;

      // otherwise, ask the user to choose
      const dialogRef = this.dialog.open(ActiveMembershipSelectorDialogComponent, {
        data: membershipsWithAdminAccess,
        disableClose: true,
        backdropClass: 'active-membership-selector-backdrop',
        panelClass: 'active-membership-dialog'
      });
      dialogRef.afterClosed().subscribe((member: Member) => {
        this.onActiveMembershipChanged(member);
      });
    }
  }

  /**
   * Change the organisation-in-context (either prompted at login or via the header menu).
   */
  onActiveMembershipChanged(activeMembership: Member): void {
    this.logger.trace('ACTIVE SECURITY: active membership changed to: ', activeMembership);

    this.logger.organisationId = activeMembership.organisationId;

    ActiveSecurityContextStateService.cachedOrganisationId = activeMembership.organisationId;

    const groupPermissions = this.flattenGroupPermissions(this.activeUser.userRoles ?? [], activeMembership.organisationId);

    this.store.update(
      setProps({
        activeMembership,
        groupPermissions
      })
    );

    // fetch roles and permission, and organisation settings for this membership
    this.activeMemberDataProvider.read$().subscribe((activeMembership) => {
      const activeOrganisationPermissions = this.flattenOrganisationPermissions(activeMembership.memberRoles ?? []);
      const activeFeaturePackPermissions = this.flattenFeaturePackPermissions(activeMembership.featurePacks);

      // active user starts with a lite copy off all memberships with Employments (for Employee Hub), so copy
      activeMembership.employments = activeMembership.employments;

      this.store.update(
        setProps({
          activeMembership,
          activeOrganisationPermissions,
          activeFeaturePackPermissions
        })
      );

      this.analyticsService.addMemberProperties(activeMembership);

      document.title = `${activeMembership?.organisationName.toUpperCase()} - Cintra Cloud`;

      let activeUser = this.activeUser;

      const userAttributes: UserAttributesModel = {
        isCintraEmployee: this.hasApplicationAuthorityTo('IsCintraEmployee'),
        organisationName: activeMembership?.organisationName,
        bureauName: activeMembership?.bureauProfile?.name,
        cognitoId: activeUser?.cognitoId,
        email: activeUser?.email
      };

      this.hotjarService.identify(userAttributes, activeUser?.cognitoId);

      this.performLoginFlow$(activeMembership).pipe(take(1)).subscribe();
    });
  }

  private performLoginFlow$(activeMembership: Member): Observable<void> {
    if (activeMembership.isPrivate) {
      return this.performPrivateLogin$().pipe(
        tap(({ privateDetails }) => {
          if (privateDetails) {
            ActiveSecurityContextStateService.cachedPrivateDetails = privateDetails;
            activeMembership = { ...activeMembership, privateDetails };
            this.store.update(setProps({ activeMembership }));
          }
        }),
        switchMap((response) =>
          response.cancelLogin ? this.signOut$() : this.fetchOrganisationPropertiesThenRedirectToBestRoute$(activeMembership)
        )
      );
    } else {
      // Normal login flow.
      return this.fetchOrganisationPropertiesThenRedirectToBestRoute$(activeMembership);
    }
  }

  private fetchOrganisationPropertiesThenRedirectToBestRoute$(activeMembership: Member): Observable<void> {
    const organisationProperties$: Observable<OrganisationPropertiesModel> = this.hasOrganisationAuthorityTo('AccessPayrolls')
      ? this.organisationPropertiesDataProvider.organisationProperties$()
      : of({ singlePayrollId: null, employeePostsConfigured: false });

    return organisationProperties$.pipe(
      tap((organisationProperties) => {
        activeMembership = { ...activeMembership, ...organisationProperties };

        this.store.update(setProps({ activeMembership, fetchingActiveUser: false }));

        this.redirectToBestRoute();
      }),
      map(() => null)
    );
  }

  private performPrivateLogin$(): Observable<PrivateOrganisationLoginResponse> {
    if (ActiveSecurityContextStateService.cachedPrivateDetails) {
      return of({ cancelLogin: false, privateDetails: ActiveSecurityContextStateService.cachedPrivateDetails });
    }

    const dialogRef = this.dialog.open(PrivateOrganisationLoginDialogComponent, {
      disableClose: true
    });

    return dialogRef.afterClosed();
  }

  private signOut$(): Observable<void> {
    this.authenticationService.signOutAndRedirect();
    return of(null);
  }

  private homeRoute = (): string => {
    if (this.isEmployeeHubIosUser) {
      return employeeHubRoutes.dashboard;
    }

    let route = '/'; // problematic as no valid route

    const cachedActiveProduct = ActiveCintraProductService.cachedActiveProduct;

    if ((this.hasBureauProfileAuthorityTo('IsBureauUser') && !cachedActiveProduct) || cachedActiveProduct === 'Bureau') {
      route = this.bureauHomeRoute();
    } else if (this.hasOrganisationAuthorityTo('AccessPayrolls')) {
      route = this.payrollsHomeRoute();
    } else if (this.hasApplicationAuthorityTo('EditOrganisations')) {
      route = `/${organisationRoutes.hub}`;
    } else if (this.hasOrganisationAuthorityTo('AccessPayrollReports')) {
      route = payrollRoutes.reports;
    } else if (this.hasOrganisationAuthorityTo('AccessGroups')) {
      route = `/${groupRoutes.groupsHub}`;
    } else if (this.hasOrganisationAuthorityTo('EditUsers')) {
      route = `/${userRoutes.hub}`;
    } else if (this.hasOrganisationAuthorityTo('ManageIntegrations')) {
      route = `/${integrationsRoutes.hub}`;
    } else if (this.canAccessAdminHub()) {
      route = getAdminHubLandingRoute(this);
    } else if (this.canAccessEmployeeHub()) {
      route = employeeHubRoutes.dashboard;
    } else if (this.isMessagingOnlyUser()) {
      route = messagingRoutes.inbox;
    } else if (this.hasNoOrganisationPermissions()) {
      route = 'no-permissions';
    }

    return route;
  };

  private payrollsHomeRoute = (): string => {
    const singlePayrollId = this.activeMembership.singlePayrollId;
    return singlePayrollId ? payrollRoutes.payrollHub(singlePayrollId) : employerRoutes.dashboard();
  };

  bureauHomeRoute = (): string => {
    if (this.hasBureauProfileAuthorityTo('ViewBureauOrganisations') || this.hasApplicationAuthorityTo('EditBureaux')) {
      return bureauRoutes.organisations;
    } else if (this.hasBureauProfileAuthorityTo('ViewBureauReports')) {
      return bureauRoutes.reports;
    } else if (this.hasBureauProfileAuthorityTo('AccessBACS')) {
      return bureauRoutes.bankFiles;
    }
  };

  /**
   * Resolves the most appropriate route for the current user and situation.
   */
  redirectToBestRoute() {
    if (!this.router) {
      this.router = this.injector.get<Router>(Router);
    }

    const activeRoute = `${location.pathname.toLowerCase()}${location.search}`;
    let nextRoute = activeRoute;

    // We only use ios-employee-hub once to set a value in local storage, so just remove it
    if (this.authenticationStateService.landingRoute === '/ios-employee-hub' || location.pathname.toLowerCase() == '/ios-employee-hub') {
      this.authenticationStateService.landingRoute = undefined;
      nextRoute = employeeHubRoutes.dashboard;
    }

    if (activeRoute.includes('auth/')) {
      // from auth to landing route / home
      this.router.navigate(['/auth', { outlets: { top: null } }]).then();
      nextRoute = this.authenticationStateService.landingRoute === '/' ? null : this.authenticationStateService.landingRoute;
      nextRoute = nextRoute ?? this.homeRoute();
      this.authenticationStateService.landingRoute = undefined;
    } else if (activeRoute.includes('user-invitations')) {
      nextRoute = this.authenticationStateService.isAuthenticated ? this.homeRoute() : `/${userRoutes.hub}`;
    } else if (activeRoute === '/') {
      nextRoute = this.homeRoute();
    }

    this.logger.trace('ACTIVE SECURITY: redirecting best route - resolved route from, to: ', activeRoute, nextRoute);

    this.router.navigateByUrl(nextRoute).then(() => {
      // indicator for cypress that authentication and routing has resolved
      this.fullscreenSpinnerService.reset();
      document.querySelector('body').setAttribute('data-app-ready', '');
    });
  }

  navigateHome() {
    if (!this.router) {
      this.router = this.injector.get<Router>(Router);
    }
    this.router.navigateByUrl(this.homeRoute()).then();
  }

  navigatePayrolls() {
    if (!this.router) {
      this.router = this.injector.get<Router>(Router);
    }
    this.router.navigateByUrl(this.payrollsHomeRoute()).then();
  }

  ngOnDestroy(): void {
    this.store.destroy();
  }
}
