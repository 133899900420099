import { EventEmitter, Injectable } from '@angular/core';
import { CalendarDayEvents, CalendarEvent, EventStatus, EventType } from '@design/components/events-calendar/state/calendar';

@Injectable({ providedIn: 'root' })
export class CalendarEventService {
  navigateEvents$ = new EventEmitter<CalendarDayEvents>();

  getEventCss = (calendarEvent: CalendarEvent, useSmallIcon = false): Record<string, boolean> => {
    return {
      booked: calendarEvent.eventStatus === EventStatus.Approved,
      requested: calendarEvent.eventStatus === EventStatus.Requested,
      cancellation: calendarEvent.eventStatus === EventStatus.Cancellation,
      sickness: calendarEvent.eventType === EventType.Sickness,
      'approved-absence': calendarEvent.eventStatus === EventStatus.ApprovedAbsence,
      'absence-requested': calendarEvent.eventStatus === EventStatus.AbsenceRequested,
      'absence-cancellation': calendarEvent.eventStatus === EventStatus.ApprovedAbsenceCancellation,
      'small-icon': useSmallIcon
    };
  };

  getMixedEventCss = (useSmallIcon = false): Record<string, boolean> => {
    return {
      mixed: true,
      'small-icon': useSmallIcon
    };
  };

  transformEventForClick = (calendarEvent: CalendarEvent, calendarDay: CalendarDayEvents): CalendarDayEvents => {
    return {
      ...calendarDay,
      hasMultipleMixedEvents: false,
      hasMultipleDayEvents: false,
      fullDayEvent: calendarEvent
    };
  };

  navigateEvent = (calendarEvent: CalendarEvent, calendarDay: CalendarDayEvents) =>
    this.navigateEvents$.emit(this.transformEventForClick(calendarEvent, calendarDay));
}
