import { ActivatedRoute } from '@angular/router';
import { Directive, inject, OnDestroy } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';

import { BehaviorSubject, EMPTY, fromEvent, Observable, of, throwError } from 'rxjs';
import { catchError, finalize, map, skipWhile, tap } from 'rxjs/operators';
import { DirtyCheckPlugin, PersistNgFormPlugin, Query, Store } from '@datorama/akita';
import { v4 as uuid } from 'uuid';

import { AbstractEntityState, EditMode } from './abstract-entity.state';
import { AbstractEntityDataProvider } from '../entity-data-provider/abstract-entity.data-provider';
import { AbstractIdentifiable } from './abstract-entity';
import { OnscreenMessagingService } from '@pattern-library/onscreen-messaging/onscreen-messaging.service';
import { LoggingService } from '@logging/logging.service';
import { environment } from '@env/environment';
import { untilDestroyed } from '@ngneat/until-destroy';
import { deepClone } from '@utils/object-utils';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { getIntModalParam } from '@utils/route-utils';
import { ModalOptions } from '@design/modals/modal-options';

export const idParam = 'id';

export interface EntityStateServiceOptions {
  autoFetch?: boolean;
  childEntity?: boolean;
  runValidatorsOnFormBind?: boolean;
  handleFetch404?: () => void;
  useIdParam?: string;
  useModalIdParam?: string;
}

@Directive()
export abstract class AbstractEntityStateService<TEntity extends AbstractIdentifiable, TEntityState extends AbstractEntityState<TEntity>>
  implements OnDestroy
{
  /**
   * The akita models store.
   */
  protected readonly store: Store<TEntityState>;
  /**
   * The akita models query, used to query the values in the models store.
   */
  protected readonly query: Query<TEntityState>;
  /**
   * Default initial value to supply to the models store.
   */
  private initialState = { editing: false, inFlight: false } as Partial<TEntityState>;
  /**
   * Used to store an initial empty object for the entity models.
   */
  protected readonly emptyEntity: any = {};
  /**
   * e.g. the id of the entity record - either we are editing the master record
   * or a child of the master record. This ensures api paths are resolved for the data provider.
   */
  masterRecordId: any;
  /**
   * Akita plugin for binding a form group and its value changes to the entity in models.
   */
  private persistForm: PersistNgFormPlugin | undefined;
  /**
   * Akita plugin for monitoring the dirty models of the entity in models -
   * it has been updated in the form but not yet persisted.
   */
  protected dirtyCheck: DirtyCheckPlugin | undefined;
  /**
   * The form group that the entity in models is bound to whilst in edit-mode.
   */
  public formGroup?: UntypedFormGroup;
  /**
   * A Subject for the form group
   */
  private formGroupSubject = new BehaviorSubject<UntypedFormGroup | undefined>(undefined);
  /**
   * An Observable for the form group's Subject which ensures containers can remain ChangeDetectionStrategy.OnPush
   */
  formGroup$: Observable<UntypedFormGroup> = this.formGroupSubject.asObservable().pipe(
    skipWhile((formGroup) => !formGroup),
    map((formGroup) => formGroup as UntypedFormGroup)
  );

  confirmDelete: () => void = () => {};

  /**
   * Enables the ability to supply a function to be executed when an entity is created,
   * on the remote backend.
   */
  onCreate: (entity: TEntity) => void = () => {};

  /**
   * Enables the ability to supply a function to be executed when an entity is fetched,
   * on the remote backend.
   */
  onFetch: (entity: TEntity | TEntity[]) => void = () => {};

  /**
   * Enables the ability to supply a function to be executed when an entity is updated,
   * on the remote backend.
   */
  onUpdate: (entity: TEntity) => void = () => {};

  /**
   * Enables the ability to supply a function to be executed when an entity is deleted,
   * on the remote backend.
   */
  onDelete: () => void = () => {};

  /**
   * handle 404 on fetch
   */
  handleFetch404?: () => void;

  isDirty$ = new Observable<boolean>();

  protected constructor(
    protected storeName: string,
    protected dataProvider: AbstractEntityDataProvider<TEntity>,
    protected route: ActivatedRoute,
    protected messaging: OnscreenMessagingService,
    protected logger: LoggingService,
    protected spawn?: () => TEntity,
    protected options: EntityStateServiceOptions = {
      autoFetch: true,
      childEntity: false,
      runValidatorsOnFormBind: false
    }
  ) {
    if (options.useModalIdParam) {
      const modalOptions: ModalOptions = inject(MAT_DIALOG_DATA);
      if (!modalOptions) throw new Error('Looking for a modal parameter but found no injected modal options');
      this.masterRecordId = getIntModalParam(modalOptions.params, options.useModalIdParam);
    } else this.masterRecordId = this.route.snapshot.params[options.useIdParam ?? idParam];

    // Set the parent path on the data provider with current entity identifier taken from the current route.
    this.dataProvider.setParentPath(this.masterRecordId);

    // set up models
    this.storeName = `${storeName}_${uuid()}`;
    this.store = new Store<TEntityState>(this.initialState, { name: this.storeName });
    this.query = new Query<TEntityState>(this.store);

    logger.trace(`(${this.storeName}) AbstractEntityStateService: constructing`, {
      options: this.options,
      masterRecordId: this.masterRecordId
    });

    // set the initial models value
    this.handleFetch404 = options.handleFetch404;

    if (this.options.autoFetch && !!this.masterRecordId) {
      this.fetchEntity();
    } else if (!this.options.autoFetch && !this.spawn) {
      if (this.options.childEntity) {
        this.entity = this.emptyEntity;
      } else {
        logger.trace(`AbstractEntityStateService: state service set to require manual entity fetch`);
      }
    } else if (this.spawn) {
      this.entity = this.spawn();
    } else {
      this.entity = this.emptyEntity;
    }

    // prevent unwanted entity updates being lost
    this.watchDirtyUnload();
    this.watchDirtyNavigate();
  }

  /**
   * Bound to the window beforeunload; employs the entity dirty-check mechanism to
   * notify the user changes would be lost by continuing. The browser controls the actions,
   * but at least cancel is an option.
   */
  private watchDirtyUnload() {
    // check for dirty models on browser close
    if (environment.suppressBeforeUnloadEvent) return;
    fromEvent(window, 'beforeunload')
      .pipe(untilDestroyed(this, 'ngOnDestroy'))
      .subscribe((event) => {
        if (!(this.dirtyCheck && this.dirtyCheck.isDirty())) return;
        this.logger.trace(`APP UNLOAD: ${this.storeName} EntityStateService has a dirty form - confirming exit...`);
        // the following opens a browser-specific standard dialog (of which we have no control)
        // cancel the event as stated by the standard.
        event.preventDefault();
        // Chrome requires returnValue to be set.
        event.returnValue = '' as any;
      });
  }

  private watchDirtyNavigate() {
    // todo: tough nut to crack. TO-287
  }

  /**
   * Cancel edit handler: reverts models to previous version of entity, after
   * checking dirtiness and optional user-confirmation to cancel
   */
  cancelEditing = (cancelFn?: () => void, context?: any) => {
    const cancel = () => {
      if (cancelFn) cancelFn.call(context || this);
      this.revertEntitySnapshot();
    };
    if (this.dirtyCheck?.isDirty()) {
      this.logger.trace('Abstract Entity State: form is dirty: ', this.formGroup);
      this.messaging.confirm('You have made edits and these changes will be lost - are you sure you want to cancel?', cancel);
    } else cancel();
  };

  /**
   * Get the latest version of models from the akita models store
   */
  protected get entityState(): TEntityState {
    return this.query.getValue();
  }

  /**
   * Write a new version of models to the akita models store.
   */
  protected set entityState(state: TEntityState) {
    this.store.update(deepClone(state));
  }

  /**
   * Creates a new version of the entity in state after making a partial mutation.
   */
  protected set properties(entity: Partial<TEntity>) {
    this.entity = { ...this.entityState.entity, ...entity } as any as TEntity;
  }

  /**
   * Gets the latest version of the entity from state.
   */
  get entity(): TEntity | undefined {
    return this.entityState.entity;
  }

  /**
   * Creates a new version of the entity in state.
   */
  set entity(entity: TEntity | undefined) {
    this.entityState = { ...this.entityState, entity };
    this.dataProvider.childId = this.options.childEntity && entity ? entity.id : undefined;
  }

  /**
   * Gets an observable of any server-side error from state.
   */
  get serverValidationErrors$(): Observable<{ [p: string]: string } | undefined> {
    return this.query.select((state) => state.serverValidationErrors);
  }

  /**
   * Get the current version of server-side errors in state.
   */
  get serverValidationErrors(): { [p: string]: string } | undefined {
    return this.query.getValue().serverValidationErrors;
  }

  /**
   * Creates a new version of server-side errors in state.
   */
  set serverValidationErrors(serverValidationErrors: { [p: string]: string } | undefined) {
    const entityState = deepClone(this.entityState);
    entityState.serverValidationErrors = serverValidationErrors;
    this.store.update(entityState);
  }

  /**
   * An observable of the current entity in state
   */
  get entity$(): Observable<TEntity> {
    return this.query
      .select((state) => state.entity)
      .pipe(
        skipWhile((entity) => !entity),
        map((entity) => entity as TEntity)
      );
  }

  /**
   * An observable of the models is-editing flag
   */
  get editing$(): Observable<boolean> {
    return this.query.select((state) => state.editing);
  }

  /**
   * Whether we have an existing entity or not
   */
  getEditMode(entity?: TEntity): EditMode {
    return (entity || this.entity)?.id?.toString().length ? 'Updating' : 'Creating';
  }

  /**
   * An observable of whether we have an existing entity or not
   */
  private get editMode$(): Observable<EditMode> {
    return this.entity$.pipe(map((e) => this.getEditMode(e)));
  }

  /**
   * An observable of whether we in the mode of updating an existing entity
   */
  get updating$(): Observable<boolean> {
    return this.editMode$.pipe(map((m) => m === 'Updating'));
  }

  /**
   * Sets whether a call to the remote api is in-flight
   */
  set inFlight(inFlight: boolean) {
    this.entityState = { ...this.entityState, inFlight };
  }

  get inFlight$(): Observable<boolean> {
    return this.query.select((state) => state.inFlight);
  }

  /**
   * Toggles a models flag that indicates whether this entity is in edit-mode or not.
   */
  toggleEditing(toState?: boolean) {
    const state = this.entityState;
    if (toState === undefined) toState = !state.editing;
    if (toState === state.editing) return;
    this.entityState = { ...state, editing: toState };
  }

  /**
   * As an edit begins, a snapshot of the current entity is created, in case the edit is cancelled.
   */
  protected createEntitySnapshot() {
    this.entityState = { ...this.entityState, entitySnapshot: this.entity };
  }

  /**
   * On cancellation of an edit, returns the entity models to the version before the edit began.
   * Resets the akita dirty-checking mechanism.
   */
  protected revertEntitySnapshot() {
    this.logger.trace('Cancelling: Reverting entity snapshot ', this.entityState.entitySnapshot);
    this.destroyDirtyCheck();
    this.entityState = { ...this.entityState, entity: this.entityState.entitySnapshot };
  }

  /**
   * Fetches the current entity from the remote backend and adds it to models.
   */
  fetchEntity = (params?: any) => {
    this.dataProvider
      .read$(params)
      .pipe(
        catchError((err) => {
          const status = err.status;
          if (status === 404 && this.handleFetch404) {
            console.warn('404 entity not found error was handled');
            this.handleFetch404();
            return of(null);
          }
          return throwError(err);
        })
      )
      .subscribe((entity: TEntity) => {
        this.entity = entity;
        this.onFetch(this.entity);
      });
  };

  /**
   * Fetches the entity with the given id from the remote backend and adds it to models.
   * Then bind the form group with the supplied function.
   */
  fetchEntityByIdAndBindFormGroup(id: any, createForm: (entity: TEntity) => UntypedFormGroup, params?: any) {
    this.setId(id);
    this.fetchEntityAndBindFormGroup(createForm, params);
  }

  /**
   * Fetches the current entity from the remote backend and then binds a form group straight after.
   * This is useful for situations where forms don't have a read component (EDIT ONLY DIALOGS).
   */
  fetchEntityAndBindFormGroup(createForm: (entity: TEntity) => UntypedFormGroup, params?: any) {
    this.dataProvider
      .read$(params)
      .pipe(
        catchError((err) => {
          const status = err.status;
          if (status === 404 && this.handleFetch404) {
            console.warn('404 entity not found error was handled');
            this.handleFetch404();
            return EMPTY;
          }
          return throwError(err);
        })
      )
      .subscribe((entity: TEntity) => {
        this.entity = entity;
        this.onFetch(this.entity);
        this.bindFormGroup(createForm);
      });
  }

  /**
   * An observable that when subscribed to will trigger a POST http event, creating the entity in the remote backend.
   */
  private createEntity$(entity: TEntity): Observable<AbstractIdentifiable> {
    this.logger.trace('AbstractEntityStateService: creating', entity);

    return this.dataProvider.create$(entity).pipe(
      tap((result) => {
        const persisted = { ...entity };
        persisted.id = result?.id;
        this.entity = persisted;
        this.onCreate(this.entity);
      })
    );
  }

  /**
   * An observable that when subscribed to will trigger a PUT http event, updating the entity in the remote backend.
   */
  private updateEntity$(entity: TEntity): Observable<any> {
    this.logger.trace('AbstractEntityStateService: updating', entity);

    return this.dataProvider.update$(entity).pipe(
      tap(() => {
        this.onUpdate(entity);
      })
    );
  }

  /*
    Can be overridden in order to transform entity before persistence
   */
  getEntity(): TEntity {
    return this.entity;
  }

  /**
   * Returns an observable that when subscribed to will remote-persist the latest models-version of the entity.
   */
  persist$(): Observable<any> {
    if (!this.formValid()) throw new Error('Form is not valid - if using a form ensure you validate it before trying to persist.');
    this.inFlight = true;
    const entity = this.getEntity();
    const creating = this.getEditMode() === 'Creating';
    const update$: Observable<any> = creating ? this.createEntity$(entity) : this.updateEntity$(entity);
    this.serverValidationErrors = undefined;
    return update$.pipe(
      catchError((err) => {
        if (err.status === 400) {
          this.serverValidationErrors = err.error.errors;

          return of(this.serverValidationErrors);
        }

        return throwError(err);
      }),
      tap(() => this.destroyDirtyCheck()),
      finalize(() => (this.inFlight = false))
    );
  }

  deleteUsing$(delete$: () => Observable<void>): Observable<any> {
    this.inFlight = true;
    this.serverValidationErrors = undefined;
    return delete$().pipe(
      tap(() => {
        this.onDelete();
      }),
      catchError((err) => {
        if (err.status === 400) {
          this.serverValidationErrors = err.error.errors;

          return of(this.serverValidationErrors);
        }

        return throwError(err);
      }),
      finalize(() => (this.inFlight = false))
    );
  }

  updateUsing$(update$: () => Observable<void>): Observable<any> {
    this.inFlight = true;
    this.serverValidationErrors = undefined;
    return update$().pipe(
      tap((result: any) => {
        this.onUpdate(result);
      }),
      catchError((err) => {
        if (err.status === 400) {
          this.serverValidationErrors = err.error.errors;

          return of(this.serverValidationErrors);
        }

        return throwError(err);
      }),
      finalize(() => (this.inFlight = false))
    );
  }

  setId = (id: any) => {
    this.masterRecordId = id;
    this.properties = { id } as Partial<TEntity>;
    this.dataProvider.setParentPath(id);
  };

  delete$(): Observable<any> {
    return this.deleteUsing$(this.dataProvider.delete$);
  }

  /**
   * Takes a function that creates a form group bound to the entity in models.
   * The form is made accessible via an observable.
   * Creates instances of the akita plugins: PersistNgFormPlugin & DirtyCheckPlugin
   */
  bindFormGroup(createForm: (entity: TEntity) => UntypedFormGroup) {
    const entity = this.entity;
    this.logger.trace(`(${this.storeName}) AbstractEntityStateService: form binding to:`, entity);

    this.destroyDirtyCheck();
    this.destroyPersistForm();

    this.formGroup = createForm(entity);
    this.formGroupSubject.next(this.formGroup);

    // displays the form with validation already run so that invalid form control data is highlighted on edit.
    if (this.options.runValidatorsOnFormBind) {
      this.logger.trace(`(${this.storeName}) AbstractEntityStateService: running form validators`, this.formGroup);
      this.formGroup.markAllAsTouched();
      this.formGroup.updateValueAndValidity();
    }

    // it is possible to rebind the form and create a new version on the stream...
    this.persistForm = new PersistNgFormPlugin<TEntity>(this.query, 'entity');
    this.persistForm.setForm(this.formGroup);
    // dirty check is destroyed on edit-completion
    this.dirtyCheck = new DirtyCheckPlugin(this.query);
    this.dirtyCheck.setHead();

    this.isDirty$ = this.dirtyCheck.isDirty$;
  }

  /**
   * Indicates the validity of the form group, and ensures invalid controls are highlighted as such.
   */

  formValid(): boolean {
    if (!this.formGroup || this.formGroup.valid) return true;
    this.logger.trace('INVALID FORM ', this.formGroup, this.formGroup);
    this.showValidationErrors(this.formGroup);
    return false;
  }

  formDirty(): boolean {
    return this.dirtyCheck && this.dirtyCheck.isDirty();
  }

  /**
   * Given a form control, finds it property name inside the form group
   */
  private getControlName(c: AbstractControl): string | null {
    const formGroup = c.parent.controls as any;
    return Object.keys(formGroup).find((name) => c === formGroup[name]) || null;
  }

  /**
   * This fixes an an issue where form control's are not highlighted as invalid.
   * (only occurs in material dialogs).
   */
  private showValidationErrors(formGroup: UntypedFormGroup) {
    this.touchAllFormFields(formGroup);
    Object.values(formGroup.controls).forEach((control) => {
      if (!control.valid) {
        /**
         * the only way known to get angular material to show validation styles without already having focused (touched) the control
         * seems only to be an issue in dialogs - todo: isolate in a stackblitz and post to angular as bug TO-288
         * the advice is to use form.markAllAsTouched...but did not work
         */
        const controlName = this.getControlName(control);
        document
          .querySelectorAll(`[formControlName=${controlName}] mat-form-field:not(.mat-form-field-invalid) .ng-invalid`)
          .forEach((el: any) => {
            if (el.focus) {
              el.focus();
              el.blur();
              return;
            }
          });
      }
    });
  }

  /**
   * Traverses the form group and its child groups, touching every form control.
   * This is useful when validating the form and highlighting invalid fields.
   */
  private touchAllFormFields(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof UntypedFormGroup) {
        this.touchAllFormFields(control);
      } else if (control instanceof UntypedFormArray) {
        control.controls.forEach((c) => this.touchAllFormFields(c as UntypedFormGroup));
      }
    });
  }

  private destroy() {
    this.destroyPersistForm();
    this.destroyDirtyCheck();
    this.store.destroy();
  }

  /**
   * Unbind the akita form-persistence mechanism.
   */
  private destroyPersistForm() {
    if (this.persistForm) this.persistForm.destroy();
  }

  /**
   * Unbind the akita form-dirty-check mechanism.
   */
  private destroyDirtyCheck() {
    this.dirtyCheck?.destroy();
    this.dirtyCheck = undefined;
  }

  ngOnDestroy(): void {
    this.destroy();
  }
}
