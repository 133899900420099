<div class="app-select" (click)="onContainerClick()">
  <app-form-label [requiredField]="required" *ngIf="formLabel">{{ formLabel }}</app-form-label>
  <mat-select
    #select
    panelClass="app-select-panel mat-elevation-z0"
    [formControl]="formControl"
    [placeholder]="label"
    [disableOptionCentering]="true"
    [disableRipple]="true"
    [ngClass]="{
      open: select.panelOpen,
      dark: selectTheme === 'dark',
      white: selectTheme === 'white'
    }"
  >
    <mat-option>
      <ngx-mat-select-search
        [disableInitialFocus]="true"
        [placeholderLabel]="searchPlaceholder ?? label"
        [noEntriesFoundLabel]="noResultsText"
        [formControl]="searchInput"
        tid="selectSearch"
      ></ngx-mat-select-search>
    </mat-option>
    <mat-option *ngFor="let item of filteredItems$ | async" [value]="dataset.getValue(item)">
      {{ dataset.getLabel(item) }}
    </mat-option>
  </mat-select>
</div>
<ng-container *ngIf="noResults$ | async">
  <div class="pt-2 pb-2"><app-body>No items matched the search criteria.</app-body></div>
</ng-container>
